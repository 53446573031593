import { Component } from 'react';

export class Analytics extends Component {
  render() { return null; }
  componentDidMount () {
    const script = document.createElement('script');
    script.async = true;
    script.src = 'https://az725175.vo.msecnd.net/scripts/jsll-4.js';
    script.onload = () => window.awa.init({ coreData: { appId: 'thirdpartycode' }});

    document.body.appendChild(script);
  }
}