import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Analytics } from './Analytics';

class Footer extends Component {
	render() {
		return (
			<footer>
				<div className="footer">
					<div className="container">
						<ul className="justify-evenly">
							<li className="menu-link-container">
								<a href="mailto:opencode@.com?subject=Third%20Party%20Source%20Code%20Disclosures&body=Please%20add%20microsoft.com%20to%20the%20email%20address." className="menu-link">Contact</a>
							</li>
							<li className="menu-link-container">
								<Link to="/tou" className="menu-link">Terms&nbsp;of&nbsp;Use</Link>
							</li>
							<li className="menu-link-container">
								<a href="https://www.microsoft.com/en-us/legal/intellectualproperty/trademarks/en-us.aspx" className="menu-link">Trademarks</a>
							</li>
							<li className="menu-link-container">
								<a href="https://go.microsoft.com/fwlink/?LinkId=248681" className="menu-link">Privacy &amp; Cookies</a>
							</li>
							<li className="menu-copyright-container">
                                <div className="footer-logo"></div>
                                <div className="copyright-logo"><p>&copy;&nbsp;2023&nbsp;Microsoft</p></div>
							</li>
						</ul>
					</div>
				</div>
				<Analytics />
			</footer>
		);
	}
}

export default Footer;
