import React, { Component } from 'react';
import Header from './Header.js';
import Content from './Content.js';
import Footer from './Footer.js';
import TermsOfUse from './TermsOfUse.js';
import {
  BrowserRouter as Router,
  Route
} from 'react-router-dom';

class App extends Component {
  render() {
    return (
      <Router>
        <div className="wrapper">
          <a id="uhfSkipToMain" className="m-skip-to-main" href="#primaryArea" tabIndex="0" style={{ zIndex:3000002 }}>Skip to main content</a>
          <Header />
          <Route exact path="/" component={Content}/>
          <Route path="/tou" component={TermsOfUse}/>
          <Footer />
        </div>
      </Router>
    );
  }
}

export default App;
