import React, { Component } from 'react';

export class ComplianceInquiry extends Component {
  render() {
    const { onCancel } = this.props;

    return (
      <div>
        <h3>License and compliance inquiry</h3>
        <p>Microsoft products often incorporate material from third parties.
          Microsoft makes certain open source code available at <a href="https://3rdpartysource.microsoft.com">https://3rdpartysource.microsoft.com</a>,
          or you may send a check or money order for US $5.00, including the
          product name, the open source component name, architecture, and version number, to:
        </p>

        <p>
          Source Code Compliance Team<br />
          Microsoft Corporation<br />
          One Microsoft Way<br />
          Redmond, WA 98052<br />
          USA
        </p>

        <p>
          Please contact <a href="mailto:opencode@.com?subject=Third%20Party%20Source%20Code%20Disclosures&body=Please%20add%20microsoft.com%20to%20the%20email%20address." className="menu-link">opencode@</a> with any questions or concerns.
        </p>

        <p>
          <a href="#goBack" onClick={ onCancel }>Back</a>
        </p>

      </div>
    );
  }
}

export default ComplianceInquiry;
