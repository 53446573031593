import React, { Component } from 'react';
import { Alert, ProgressBar } from 'react-bootstrap';
import { DetailsList, DetailsListLayoutMode, SelectionMode } from '@fluentui/react/lib/DetailsList';
import { Announced } from '@fluentui/react/lib/Announced';
import { ScrollablePane } from '@fluentui/react/lib/ScrollablePane';
import { Sticky } from '@fluentui/react/lib/Sticky';
import { Fabric } from '@fluentui/react/lib/Fabric';
import { TextField } from '@fluentui/react/lib/TextField';
import { Stack } from '@fluentui/react/lib/Stack';
import { Text } from '@fluentui/react/lib/Text';

class Downloads extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: [],
            isLoading: false,
            filtered: []
        };
    }

    componentDidMount() {
        this.setState({ isLoading: true });
        fetch('/downloads')
            .then(response => {
                if (!response.ok) {
                    throw new Error('Something went wrong ...');
                }
                return response.json();
            })
            .then(data => {
                this.setState({ isLoading: false, data, filtered: data });
            })
            .catch(error => this.setState({ isLoading: false, error }));;
    }

    onChangeText(_event, text) {
        this.setState({
            filtered: text ? this.state.data.filter(i => i.product.toLowerCase().indexOf(text.toLowerCase()) > -1) : this.state.data,
        });
    };

    render() {
        const { filtered, isLoading, error } = this.state;

        if (isLoading) {
            return <ProgressBar striped variant='info' now={100} label='Loading disclosures...' />;
        }

        if (error) {
            return <Alert variant='danger' transition={false}>{error.message}</Alert>;
        }

        const columns = [
            {
                key: 'product',
                name: 'Product',
                fieldName: 'product',
                minWidth: 175,
                maxWidth: 300,
                isResizable: true,
                isPadded: true
            },
            {
                key: 'release',
                name: 'Release',
                fieldName: 'release',
                minWidth: 150,
                maxWidth: 150,
                isResizable: true,
            },
            {
                key: 'dependency',
                name: 'Dependency',
                fieldName: 'dependency',
                minWidth: 150,
                maxWidth: 275,
                isResizable: true,
                data: 'string',
                isPadded: true
            },
            {
                key: 'platform',
                name: 'Platform',
                fieldName: 'platform',
                minWidth: 50,
                maxWidth: 100,
                isResizable: true,
                data: 'string',
                isPadded: true
            },
            {
                key: 'download',
                name: 'Download',
                minWidth: 60,
                maxWidth: 100,
                isResizable: true,
                data: 'string',
                isPadded: true,
                onRender: (item) => {
                    return <a href={item.url}>Download</a>;
                }
            }
        ];

        return (
            <div id="Downloads">
                <Fabric>
                    <div style={{ paddingBottom: 10 }}>
                        <TextField label="Filter by product:" onChange={this.onChangeText.bind(this)} />
                    </div>

                    <Announced message={`${filtered.length} results filtered`} />

                    <div style={{ height: '52vh', position: 'relative', maxHeight: 'inherit' }}>
                            <ScrollablePane>
                                <DetailsList
                                    flexMargin={1}
                                    items={filtered}
                                    columns={columns}
                                    selectionMode={SelectionMode.none}
                                    getKey={item => item.key}
                                    setKey="none"
                                    layoutMode={DetailsListLayoutMode.justified}
                                    isHeaderVisible={true}
                                />
                                {!this.state.filtered.length && (
                                    <Stack horizontalAlign='center'>
                                        <Text>No product found</Text>
                                    </Stack>
                                )}
                            </ScrollablePane>
                    </div>
                </Fabric>
            </div>
        );
    }
}

export default Downloads;
