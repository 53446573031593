import React, { Component } from 'react';
import Downloads from './Downloads';
import { ComplianceInquiry } from './ComplianceInquiry';

class Content extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isShowingInquiryContent: false
    };

    this.handleInquiryClick = this.handleInquiryClick.bind(this);
    this.handleCancelClick = this.handleCancelClick.bind(this);
  }

  handleInquiryClick(e) {
    e.preventDefault();
    this.setState({isShowingInquiryContent: true});
    return false;
  }

  handleCancelClick(e) {
    e.preventDefault();
    this.setState({isShowingInquiryContent: false});
    return false;
  }

    render() {
      const { isShowingInquiryContent } = this.state;
        return (
            <div className="content">
                <div className="container">
                <div id="primaryArea" className="main-content" role="main">
                    <p>
                    This archive provides you with the notices and source code for certain third party components shipped with Microsoft products, in accordance with the corresponding licenses that contain disclosure obligations. Please note that we frequently contribute our modifications back to the original open source projects, so you may also find our contributions in the associated open source projects.
                    &nbsp;
                    {isShowingInquiryContent ? null :
                      <a href="#showInquiry" onClick={this.handleInquiryClick} className="menu-link highlighted-link">Compliance Inquiry</a>
                    }
                    </p>
                    {isShowingInquiryContent ? <ComplianceInquiry onCancel={this.handleCancelClick} /> : <Downloads />}
                </div>
                </div>
          </div>
        );
    }
}

export default Content;