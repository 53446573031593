import React, { Component } from 'react';
import logo from './images/logo.png';
import { Link } from 'react-router-dom';

class Header extends Component {
    render() {
        return (
            <header id="masthead" role="banner" className="site-header container">
                <a href="https://www.microsoft.com"><img src={logo} style={{'marginTop': '12px', width: '102px', height: '24px'}} title="Microsoft logo" alt="Microsoft logo" /></a><br />
                <div className="site-branding">
                    <h1 className="site-title"><Link to="/" rel="home" style={{'fontSize': '30px'}} tabIndex="-1">Third Party Disclosures</Link></h1>
                </div>
            </header>
        );
    }
}

export default Header;
